import { s, arrayOf, object } from 'modules/types'
import Field, { FieldType } from './Field'
import Question, { QUESTION_TYPE, QuestionType } from './Question'
import FieldAnswer, { FieldAnswerType } from './FieldAnswer'
import QuestionAnswer, { QuestionAnswerType } from './QuestionAnswer'
import { getObjectOrUndefined } from 'utils'

export class FieldWithQuestionAnswer {
	getType = (questionId = this.question.id) => {
		if (this.field.isMoreDetails) {
			return this.questions.find(question => question.id === questionId).type
		} else {
			return this.question.type
		}
	}
	getAnswer = (questionId = this.question.id, fieldAnswerId) => {
		let questionAnswer
		if (this.field.allowMultipleAnswers && this.field.isMoreDetails) {
			const fieldAnswer = this.fieldAnswers.find(fieldAnswer => fieldAnswer.id === fieldAnswerId)
			if (fieldAnswer === undefined) return undefined

			questionAnswer = fieldAnswer.questionAnswers.find(questionAnswer => questionAnswer.questionId === questionId)
		} else if (this.field.isMoreDetails) {
			questionAnswer = this.fieldAnswer.questionAnswers.find(questionAnswer => questionAnswer.questionId === questionId)
		} else if (this.field.allowMultipleAnswers) {
			questionAnswer = this.fieldAnswers.find(fieldAnswer => fieldAnswer.questionAnswer.questionId === questionId)
		} else {
			questionAnswer = this.fieldAnswer === undefined ? this.questionAnswer : this.fieldAnswer.questionAnswer
		}

		const answer = getObjectOrUndefined(questionAnswer, 'answer')

		if (answer === undefined) return undefined

		switch (this.getType(questionId)) {
			case QUESTION_TYPE.MULTIPLE_CHOICE:
				const question = this.field.isMoreDetails
					? this.questions.find(question => question.id === questionId)
					: this.question
				const choices = getObjectOrUndefined(question, 'choices')

				if (choices === undefined || choices.length === 0) return undefined

				return choices.find(choice => choice.id === answer)
			case QUESTION_TYPE.DATE:
				return new Date(parseInt(answer, 10))
			case QUESTION_TYPE.NUMBER:
			case QUESTION_TYPE.TEXT:
			default:
				return answer
		}
	}

	constructor({
		field,
		questions,
		question,
		field_answer: fieldAnswer,
		field_answers: fieldAnswers,
		question_answer: questionAnswer,
		question_answers: questionAnswers,
	}) {
		this.field = new Field(field)
		this.question = question && new Question(question)
		this.questions = questions && questions.map(item => new Question(item))
		this.fieldAnswer = fieldAnswer && new FieldAnswer(fieldAnswer)
		this.fieldAnswers = fieldAnswers && fieldAnswers.map(item => new FieldAnswer(item))
		this.questionAnswer = questionAnswer && new QuestionAnswer(questionAnswer)
		this.questionAnswers = questionAnswers && questionAnswers.map(item => new QuestionAnswer(item))

		this.allowMultipleAnswers = this.field.allowMultipleAnswers
		this.isMoreDetails = this.field.isMoreDetails
	}
}

export const FieldWithQuestionAnswerType = s({
	field: FieldType,
	questions: arrayOf(QuestionType),
	question: QuestionType,
	fieldAnswers: arrayOf(FieldAnswerType),
	questionAnswer: object,
	questionAnswers: arrayOf(QuestionAnswerType),
})

export default FieldWithQuestionAnswer
